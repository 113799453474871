import { useState, useRef, useEffect, useCallback } from "react";
import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import EmailIcon from "@mui/icons-material/Email";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { useDocument } from "hooks/useDocument";
import { useAuthContext } from "context/AuthContext";

//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import SubmissionProgress from "components/organisms/SubmissionProgress";
//import PermissionDenied from "components/organisms/PermissionDenied";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";

//import ModeEditIcon from "@mui/icons-material/ModeEdit";
//import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
//import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { Table, Popover, Whisper, Input, Pagination, TagPicker } from "rsuite";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

//import { Formik, Form } from "formik";
//import form from "pages/nudges/groupA/schemas/form";
//import NudgeDetails from "pages/nudges/groupA/manage/components/NudgeDetails";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCalculatorManager } from "pages/calculator/hooks/useCalculatorManager";
//import { fi } from "date-fns/locale";

import iconSend from "assets/images/icons8-send-100.png";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
//import initialValues from "pages/nudges/groupA/schemas/initialValues";
//import { List } from "rsuite";

const { Column, HeaderCell, Cell } = Table;
const collectionPath36mTimePoint = "month36_timepoint_v01";
const collectionUsers = "users";
const replies = [
  {
    code: "NR_NC",
    desc: "No risk and No concerns were identified; continue to nurture the child.",
  },
  {
    code: "NR_C",
    desc: "There is no risk, but parents have raised concerns: Engage the child in activities and monitor for progress.",
  },
  {
    code: "LR_NC",
    desc: "Low risk and No concerns were identified: Engage the child in activities and monitor for progress.It is necessary to redo the assessment that indicated low risk.",
  },
  {
    code: "LR_C",
    desc: "There is low risk, but parents have raised concerns: Engage the child in activities and monitor for progress. It is necessary to redo the assessment that indicated low risk.",
  },
  {
    code: "HR_NC",
    desc: "High risk and No concerns were identified: Engage the child in activities and monitor for progress.It is necessary to redo the assessment that indicated high risk.",
  },
  {
    code: "HR_C",
    desc: "Both high risk and parental concerns were identified: Engage the child in activities and monitor for progress.It is necessary to redo the assessment that indicated high risk.",
  },
  {
    code: "HR_NC_rd",
    desc: "High risk and no concerns were identified during retest (i.e.,the 2nd round of  assessments at the same time point). Engage the child in activities and refer to polyclinic.",
  },
  {
    code: "HR_C_rd",
    desc: "Both high risk and parental concerns were identified  during retest (i.e.,the 2nd round of  assessments at the same time point).  Engage the child in activities and refer to polyclinic.",
  },
  {
    code: "LR_NC_rd",
    desc: "Low risk and no concerns were identified during  during retest (i.e.,the 2nd round of  assessments at the same time point).  Engage the child in activities and refer to polyclinic.",
  },
  {
    code: "LR_C_rd",
    desc: "Both low risk and parental concerns were identified during  during retest (i.e.,the 2nd round of  assessments at the same time point). Engage the child in activities and refer to polyclinic. ",
  },
];

const defaultColumns = [
  {
    key: "cradle2Id",
    label: "Id",
    flexGrow: 1,
  },
  {
    key: "group",
    label: "Group A/B",
    flexGrow: 1,
  },
  {
    key: "dob",
    label: "DoB",
    flexGrow: 1,
  },
  {
    key: "gender",
    label: "Gender",
    flexGrow: 1,
  },
  {
    key: "doa",
    label: "DoA",
    flexGrow: 1,
  },
  {
    key: "ageInDays",
    label: "Age In Days",
    flexGrow: 1,
  },
  {
    key: "ageInMonths",
    label: "Age In Mths",
    flexGrow: 1,
  },
  {
    key: "ageInMonthsDays",
    label: "Age In Mths, Days",
    flexGrow: 1,
  },
  {
    key: "duedateASQ3",
    label: "ASQ-3 Due Date",
    flexGrow: 1,
  },
  {
    key: "duedateASQSE",
    label: "ASQ-SE Due Date",
    flexGrow: 1,
  },
  {
    key: "test_retest",
    label: "Test/Retest",
    flexGrow: 1,
  },
  {
    key: "asq3_communicationSum",
    label: "Sum_C",
    flexGrow: 1,
  },
  {
    key: "asq3_grossmotorSum",
    label: "Sum_G",
    flexGrow: 1,
  },
  {
    key: "asq3_finemotorSum",
    label: "Sum_F",
    flexGrow: 1,
  },
  {
    key: "asq3_problemsolvingSum",
    label: "Sum_P",
    flexGrow: 1,
  },
  {
    key: "asq3_personalsocialSum",
    label: "Sum_PS",
    flexGrow: 1,
  },
  {
    key: "asqse2_total",
    label: "Sum_SE",
    flexGrow: 1,
  },
  {
    key: "sd_emotionalproblems",
    label: "SD_EP",
    flexGrow: 1,
  },
  {
    key: "sd_conductproblems",
    label: "SD_CP",
    flexGrow: 1,
  },
  {
    key: "sd_hyperactivity",
    label: "SD_H",
    flexGrow: 1,
  },
  {
    key: "sd_peerproblems",
    label: "SD_PP",
    flexGrow: 1,
  },
  {
    key: "sd_prosocial",
    label: "SD_PS",
    flexGrow: 1,
  },
  {
    key: "sd_total",
    label: "SD_TOTAL",
    flexGrow: 1,
  },
  {
    key: "assessmentCodes",
    label: "Code",
    flexGrow: 1,
  },
  {
    key: "action",
    label: "ACTION",
    flexGrow: 1,
  },
  {
    key: "retest_duedate",
    label: "Retest Due Date",
    flexGrow: 1,
  },
];

function Calculator36months() {
  console.log("Run Calculator36months");
  const { retrieveDoc, retrieveDocs, createDoc, updateDoc, serverTimestamp } =
    useDocument();
  const { user } = useAuthContext();
  const { modeTitle, response } = useCalculatorManager();
  const responseMonth36 = response.data;

  const [columnKeys, setColumnKeys] = useState(null);
  const [columns, setColumns] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const showCutoffLabel = (cutoffLabel) => {
    return cutoffLabel === "BLACK" ? (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "whitesmoke",
        }}
      >
        <MDTypography variant="body3" color="dark">
          BLACK
        </MDTypography>
      </MDBox>
    ) : cutoffLabel === "WHITE" ? (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "darkgrey",
        }}
      >
        <MDTypography variant="body3" color="white">
          WHITE
        </MDTypography>
      </MDBox>
    ) : (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "lightgrey",
        }}
      >
        <MDTypography variant="body3" color="dark">
          GREY
        </MDTypography>
      </MDBox>
    );
  };

  const Asq3CommunicationCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-Communication";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 2a</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication02a}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication02a_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Comm 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communication06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_C</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {showCutoffLabel(rowData.asq3_communicationSumLabel)}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_communicationSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3GrossMotorCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-Gross Motor";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Gross Motor 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotor06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_G</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {showCutoffLabel(rowData.asq3_grossmotorSumLabel)}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_grossmotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3FineMotorCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-Fine Motor";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Fine Motor 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotor06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_F</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {showCutoffLabel(rowData.asq3_finemotorSumLabel)}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3ProblemSolvingCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-Problem Solving";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 3a</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving03a}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving03a_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Prob. Solving 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_problemsolving06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_P</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {showCutoffLabel(rowData.asq3_problemsolvingSumLabel)}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_finemotorSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asq3PersonalSocialCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-Personal Social";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Personal Social 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocial06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Sum_P</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            {showCutoffLabel(rowData.asq3_personalsocialSumLabel)}
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.asq3_personalsocialSum}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const Asqse2CellElm = (rowData, label, dataKey1, dataKey2) => {
    return (
      <>
        <Grid item xs={2}>
          <MDTypography variant="body3">{label}</MDTypography>
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"center"}>
          <MDTypography variant="body3">{rowData[dataKey1]}</MDTypography>
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"center"}>
          <MDTypography variant="body3">{rowData[dataKey2]}</MDTypography>
        </Grid>
      </>
    );
  };

  const Asqse2Cell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": ASQ3-SE2";
    const speaker = (
      <Popover title={title} style={{ width: 800 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 1", "asqse2_01", "asqse2_01_score")}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 21a",
            "asqse2_21a",
            "asqse2_21a_score"
          )}
          {Asqse2CellElm(rowData, "ASQ-SE2 2", "asqse2_02", "asqse2_02_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 22", "asqse2_22", "asqse2_22_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 3", "asqse2_03", "asqse2_03_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 23", "asqse2_23", "asqse2_23_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 4", "asqse2_04", "asqse2_04_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 24", "asqse2_24", "asqse2_24_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 5", "asqse2_05", "asqse2_05_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 25", "asqse2_25", "asqse2_25_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 6", "asqse2_06", "asqse2_06_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 26", "asqse2_26", "asqse2_26_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 7", "asqse2_07", "asqse2_07_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 27", "asqse2_27", "asqse2_27_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 8", "asqse2_08", "asqse2_08_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 28", "asqse2_28", "asqse2_28_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 9", "asqse2_09", "asqse2_09_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 29", "asqse2_29", "asqse2_29_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 10", "asqse2_10", "asqse2_10_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 30", "asqse2_30", "asqse2_30_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 11", "asqse2_11", "asqse2_11_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 31", "asqse2_31", "asqse2_31_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 12", "asqse2_12", "asqse2_12_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 32", "asqse2_32", "asqse2_32_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 13", "asqse2_13", "asqse2_13_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 33", "asqse2_33", "asqse2_33_score")}
          {Asqse2CellElm(rowData, "ASQ-SE2 14", "asqse2_14", "asqse2_14_score")}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 34a",
            "asqse2_34a",
            "asqse2_34a_score"
          )}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 15a",
            "asqse2_15a",
            "asqse2_15a_score"
          )}
          {Asqse2CellElm(
            rowData,
            "ASQ-SE2 35a",
            "asqse2_35a",
            "asqse2_35a_score"
          )}
          {Asqse2CellElm(rowData, "ASQ-SE2 16", "asqse2_16", "asqse2_16_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 17", "asqse2_17", "asqse2_17_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 18", "asqse2_18", "asqse2_18_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 19", "asqse2_19", "asqse2_19_score")}
          <Grid item xs={6}></Grid>
          {Asqse2CellElm(rowData, "ASQ-SE2 20", "asqse2_20", "asqse2_20_score")}
          <Grid item xs={2}>
            <MDTypography variant="body3">Sum_SE</MDTypography>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {showCutoffLabel(rowData.asqse2_totalLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">{rowData.asqse2_total}</MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const getCutoffSDLabel = (cutoffLabel) => {
    return (
      <MDBox
        p={0.8}
        sx={{
          borderRadius: "8px",
          backgroundColor: "whitesmoke",
        }}
      >
        <MDTypography variant="body3" color="dark">
          {cutoffLabel}
        </MDTypography>
      </MDBox>
    );
  };

  const SdEmotionalProblemsCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Emotional Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 3</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties03}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties03_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 8</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties08}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties08_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 13</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties13}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties13_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 16</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties16}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties16_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 24</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties24}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties24_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">EP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_emotionalproblemsLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_emotionalproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdConductProblemsCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Conduct Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 5</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties05}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties05_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 7</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties07}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties07_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 12</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties12}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties12_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 18</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties18}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties18_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 22</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties22}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties22_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">CP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_conductproblemsLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_conductproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdHyperactivityCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Hyperactivity";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 2</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties02}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties02_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 10</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties10}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties10_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 15</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties15}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties15_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 21</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties21}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties21_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 25</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties25}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties25_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">HP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_hyperactivityLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_hyperactivity}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdPeerProblemsCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Peer Problems";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 6</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties06}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties06_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 11</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties11}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties11_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 14</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties14}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties14_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 19</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties19}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties19_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 23</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties23}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties23_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">PP Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_peerproblemsLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.sd_peerproblems}
            </MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdProsocialCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Prosocial";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 1</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties01}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties01_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 4</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties04}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties04_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 9</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties09}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties09_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 17</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties17}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties17_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">Item 20</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties20}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {rowData.strengthsdifficulties20_score}
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">PS Score</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_prosocialLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">{rowData.sd_prosocial}</MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const SdTotalCell = ({ rowData, dataKey, ...props }) => {
    const title = rowData.cradle2Id + ": SD-Total";
    const speaker = (
      <Popover title={title} style={{ width: 400 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <MDTypography variant="body3">SD Total</MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">
              {getCutoffSDLabel(rowData.sd_totalLabel)}
            </MDTypography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <MDTypography variant="body3">{rowData.sd_total}</MDTypography>
          </Grid>
        </Grid>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="auto" speaker={speaker}>
          <href>
            <MDTypography variant="body2">{rowData[dataKey]}</MDTypography>
          </href>
        </Whisper>
      </Cell>
    );
  };

  const getData = () => {
    if (sortColumn && sortType) {
      if (sortColumn === "cradle2Id" || sortColumn === "group") {
        data.sort((a, b) => {
          let x = a[sortColumn].toUpperCase();
          let y = b[sortColumn].toUpperCase();
          const minLength = Math.min(x.length, y.length);
          let idx = 0;
          let retValue = 0;
          while (idx < minLength) {
            const xChar = x.charCodeAt(idx);
            const yChar = y.charCodeAt(idx);

            if (xChar !== yChar) {
              if (sortType === "asc") {
                retValue = xChar - yChar;
                break;
              } else {
                retValue = yChar - xChar;
                break;
              }
            } else {
              idx += 1;
            }
          }
          return retValue;
        });
      } else {
        data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (sortType === "asc") {
            return parseFloat(x) - parseFloat(y);
          } else {
            return parseFloat(y) - parseFloat(x);
          }
        });
      }
    }

    const start = limit * (page - 1);
    const end = start + limit;
    return data.slice(start, end);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const [emailtoList, setEmailtoList] = useState([]);
  const [emailKeys, setEmailKeys] = useState([]);
  const getUserSettings = useCallback(async () => {
    const retrievedUser =
      user.uid && (await retrieveDoc(collectionUsers, user.uid));
    if (retrievedUser.data.defaultEmailtoList !== undefined) {
      const emails = retrievedUser.data.defaultEmailtoList.map((item) => ({
        label: item.label,
        key: item.key,
      }));
      setEmailtoList(emails);
      setEmailKeys(emails.map((email) => email.key));

      const columnKeys = retrievedUser.data.tableMonth36_columnKeys;
      const columns = defaultColumns.filter((column) =>
        columnKeys.some((key) => key === column.key)
      );

      setColumnKeys(columnKeys);
      setColumns(columns);
    }
  }, [retrieveDoc, user]);

  useEffect(() => {
    if (!!responseMonth36) {
      setData(responseMonth36);
    }
    getUserSettings();
  }, [responseMonth36, getUserSettings]);

  const [openDlg, setOpenDlg] = useState(false);
  const [msgDlg, setMsgDlg] = useState(null);
  const handleCloseDlg = () => {
    setOpenDlg(false);
  };

  const [openInputDlg, setOpenInputDlg] = useState(false);
  const handleCloseInputDlg = async () => {
    setOpenInputDlg(false);
    if (newCradle2Id.current !== null) {
      const newrows = data.map((row) => {
        if (row.cradle2Id === currentCradle2Id) {
          row.cradle2Id = newCradle2Id.current;
        }
        return row;
      });
      setData(newrows);
      // Update Retest Due Date at Firestore
      await updateDoc(collectionPath36mTimePoint, docId.current, {
        cradle2Id: newCradle2Id.current,
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
    }
  };

  const [openInputGenderDlg, setOpenInputGenderDlg] = useState(false);
  const handleCloseInputGenderDlg = async () => {
    if (newGender.current !== null && newGender.current !== currentGender) {
      const query = {
        whereQueries: [
          {
            field: "userName",
            condition: "==",
            value: currentCradle2Id.toUpperCase(),
          },
        ],
        orderByQueries: [
          {
            field: "userName",
            direction: "desc",
          },
        ],
      };
      const retrievedParent = await retrieveDocs("parents", query);

      if (retrievedParent.length > 0) {
        // Update new gender on parent document
        const docId = retrievedParent[0].id;
        const children = retrievedParent[0].data.children;
        // Update new gender
        children[0].gender = newGender.current;
        await updateDoc("parents", docId, {
          children: children,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        // Only one child for one parent
        const childDocId = retrievedParent[0].data.childrenIds[0];
        await updateDoc("children", childDocId, {
          gender: newGender.current,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        // Update row on table
        const newrows = data.map((row) => {
          if (row.cradle2Id === currentCradle2Id) {
            row.gender = newGender.current;
          }
          return row;
        });
        setData(newrows);
        setOpenInputGenderDlg(false);
      } else {
        setOpenInputGenderDlg(false);
        setOpenMsgDlg(true);
      }
    }
  };

  const [openMsgDlg, setOpenMsgDlg] = useState(false);
  const handleCloseMsgDlg = () => {
    setOpenMsgDlg(false);
  };

  const [subject, setSubject] = useState(null);
  const [contents, setContents] = useState(null);
  const [openEmail, setOpenEmail] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [currentCradle2Id, setCurrentCradle2Id] = useState(null);
  const [currentGender, setCurrentGender] = useState(null);
  const newCradle2Id = useRef(null);
  const newGender = useRef(null);
  const docId = useRef(null);

  const getOpenEndedQuestions = (rowData) => {
    let contents = "";
    const newline = "\r\n";
    contents += "The open-ended questions of the ASQ-3:" + newline;
    contents += "Overall 1b: " + rowData.overall01b + newline;
    contents += "Overall 2b: " + rowData.overall02b + newline;
    contents += "Overall 3b: " + rowData.overall03b + newline;
    contents += "Overall 4b: " + rowData.overall04b + newline;
    contents += "Overall 5b: " + rowData.overall05b + newline;
    contents += "Overall 6b: " + rowData.overall06b + newline;
    contents += "Overall 7b: " + rowData.overall07b + newline;
    contents += "Overall 8b: " + rowData.overall08b + newline;
    contents += "Overall 9b: " + rowData.overall09b + newline;
    contents += "Overall 10b: " + rowData.overall10b + newline;
    contents += newline;

    contents += "The open-ended questions of the ASQ-SE:" + newline;
    contents += "ASQSE 15b: " + rowData.asqse2_15b + newline;
    contents += "ASQSE 34b: " + rowData.asqse2_34b + newline;
    contents += "ASQSE 35b: " + rowData.asqse2_35b + newline;
    contents += "ASQSE 36 Overall b: " + rowData.asqse2_36b + newline;
    contents += "ASQSE 37 Overall b: " + rowData.asqse2_37b + newline;
    contents += "ASQSE 38: " + rowData.asqse2_38 + newline;
    contents += newline;

    contents += "The parental concern questionnaire:" + newline;
    contents += "PC 1: " + rowData.parentalconcern01 + newline;
    contents += "PC 2: " + rowData.parentalconcern02 + newline;
    contents += "PC 3: " + rowData.parentalconcern03 + newline;
    contents += "PC 4: " + rowData.parentalconcern04 + newline;
    contents += "PC 5: " + rowData.parentalconcern05 + newline;
    contents += "PC 6: " + rowData.parentalconcern06 + newline;
    contents += newline;

    return contents;
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <MDBox mb={4}></MDBox>
      {(showProgress || response.isPending) && <SubmissionProgress />}

      {openEmail && (
        <Card id="email">
          <MDBox p={2}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="body1" color="dark">
                EMAIL
              </MDTypography>
              <MDButton
                size="medium"
                variant="text"
                color="dark"
                iconOnly
                onClick={() => {
                  setOpenEmail(false);
                }}
              >
                <CloseIcon />
              </MDButton>
            </MDBox>
            <MDBox mb={1}></MDBox>
            <MDBox display="flex" justifyContent="left" flexDirection="row">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{
                  width: "100px",
                  border: "1px solid #cecece",
                  borderRadius: "8px",
                }}
              >
                <MDButton
                  variant="text"
                  size="small"
                  color="dark"
                  display="flex"
                  onClick={async () => {
                    setShowProgress(true);
                    await createDoc(
                      "emailrecords",
                      {
                        createdAt: serverTimestamp(),
                        receivers: emailKeys,
                        subjectTitle: subject,
                        contents: contents,
                      },
                      user.uid
                    );
                    setShowProgress(false);
                    setSubject("");
                    setContents("");
                    setOpenDlg(true);
                  }}
                >
                  <MDBox
                    component="img"
                    src={iconSend}
                    alt="Assessment"
                    width={{ xs: "100%" }}
                    opacity={0.5}
                  />
                </MDButton>
                <MDTypography variant="body3" color="dark">
                  SEND
                </MDTypography>
              </MDBox>
              <MDBox mr={5}></MDBox>
              <MDBox
                display="flex"
                justifyContent="left"
                flexDirection="column"
                sx={{ width: "100%" }}
              >
                <Grid
                  container
                  rowSpacing={{ xs: 1, sm: 1 }}
                  columnSpacing={{ xs: 1, sm: 3 }}
                  p={0}
                >
                  <Grid item xs={1}>
                    <MDTypography variant="body3" color="dark">
                      To:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={11}>
                    {emailtoList.length > 0 && (
                      <TagPicker
                        creatable
                        data={emailtoList}
                        style={{ width: "100%" }}
                        menuStyle={{ width: 300 }}
                        /*onCreate={(value, item) => {
                          console.log(item);
                          console.log(value);
                        }}*/
                        labelKey="label"
                        valueKey="key"
                        value={emailKeys}
                        onChange={setEmailKeys}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <MDTypography variant="body3" color="dark">
                      Subject:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={11}>
                    <Input
                      placeholder="Subject"
                      value={subject}
                      onChange={(val) => {
                        setSubject(val);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox mb={2}></MDBox>
            <MDBox>
              <Input
                as="textarea"
                rows={20}
                value={contents}
                placeholder="Contents"
                onChange={(val) => {
                  setContents(val);
                }}
              />
            </MDBox>
          </MDBox>
        </Card>
      )}

      <MDBox mb={2}></MDBox>

      <Card id="calculator-information" sx={{ overflow: "visible" }}>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1 }}
          columnSpacing={{ xs: 1, sm: 3 }}
          p={2}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              id="search-textfield"
              label="Search"
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value;

                if (!!responseMonth36 && value.length === 0) {
                  setData(responseMonth36);
                }

                if (!!responseMonth36 && value.length > 0) {
                  const subrows = responseMonth36.filter((row) => {
                    return row.cradle2Id.substring(0, value.length) === value;
                  });
                  setData(subrows);
                }
              }}
            />
          </Grid>
          {columns !== null && (
            <Grid
              item
              xs={12}
              sm={12}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
            >
              <MDTypography variant="body3" color="dark">
                Filter Columns:
              </MDTypography>
              <MDBox mr={3}></MDBox>
              <TagPicker
                data={defaultColumns}
                labelKey="label"
                valueKey="key"
                value={columnKeys}
                onChange={async (columnKeys) => {
                  const columns = defaultColumns.filter((column) =>
                    columnKeys.some((key) => key === column.key)
                  );
                  setColumnKeys(columnKeys);
                  setColumns(columns);

                  await updateDoc(collectionUsers, user.uid, {
                    tableMonth36_columnKeys: columnKeys,
                    modifiedAt: serverTimestamp(),
                    modifiedBy: user.uid,
                  });
                }}
                style={{ width: "auto" }}
                cleanable={false}
              />
            </Grid>
          )}

          {columns !== null && (
            <Grid item xs={12} sm={12}>
              {data.length > 0 && (
                <>
                  <MDBox mt={3}>
                    <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={5}
                      size="xs"
                      layout={["total", "-", "limit", "|", "pager", "skip"]}
                      total={data.length}
                      limitOptions={[10, 30, 50, 100]}
                      limit={limit}
                      activePage={page}
                      onChangePage={setPage}
                      onChangeLimit={handleChangeLimit}
                    />
                  </MDBox>
                  <MDBox mt={3}>
                    <Table
                      height={1000}
                      data={getData()}
                      //hover={true}
                      showHeader={true}
                      bordered={true}
                      cellBordered={true}
                      onSortColumn={handleSortColumn}
                      sortColumn={sortColumn}
                      sortType={sortType}
                      loading={loading}
                      //onRowClick={rowData => {
                      //  console.log(rowData);
                      //}}
                    >
                      {columns.map((column) => {
                        const { key, label, ...rest } = column;

                        return key === "asq3_communicationSum" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asq3CommunicationCell dataKey="asq3_communicationSum" />
                          </Column>
                        ) : key === "asq3_grossmotorSum" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asq3GrossMotorCell dataKey="asq3_grossmotorSum" />
                          </Column>
                        ) : key === "asq3_finemotorSum" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asq3FineMotorCell dataKey="asq3_finemotorSum" />
                          </Column>
                        ) : key === "asq3_problemsolvingSum" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asq3ProblemSolvingCell dataKey="asq3_problemsolvingSum" />
                          </Column>
                        ) : key === "asq3_personalsocialSum" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asq3PersonalSocialCell dataKey="asq3_personalsocialSum" />
                          </Column>
                        ) : key === "asqse2_total" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Asqse2Cell dataKey="asqse2_total" />
                          </Column>
                        ) : key === "sd_emotionalproblems" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdEmotionalProblemsCell dataKey="sd_emotionalproblems" />
                          </Column>
                        ) : key === "sd_conductproblems" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdConductProblemsCell dataKey="sd_conductproblems" />
                          </Column>
                        ) : key === "sd_hyperactivity" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdHyperactivityCell dataKey="sd_hyperactivity" />
                          </Column>
                        ) : key === "sd_peerproblems" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdPeerProblemsCell dataKey="sd_peerproblems" />
                          </Column>
                        ) : key === "sd_prosocial" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdProsocialCell dataKey="sd_prosocial" />
                          </Column>
                        ) : key === "sd_total" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <SdTotalCell dataKey="sd_total" />
                          </Column>
                        ) : key === "action" ? (
                          <Column {...rest} key={key}>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell fixed="right">
                              {(rowData) => {
                                return (
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="left"
                                  >
                                    <MDButton
                                      size="small"
                                      variant="gradient"
                                      color="info"
                                      iconOnly
                                      onClick={() => {
                                        setOpenEmail(true);
                                        setSubject(
                                          rowData.cradle2Id +
                                            ": The results of the assessments at the 36-month timepoint"
                                        );
                                        setMsgDlg(
                                          "Assessment report for " +
                                            rowData.cradle2Id +
                                            " sent successfully!"
                                        );
                                        const codeArray =
                                          rowData.assessmentCodes.split(",");

                                        let contents =
                                          "Dear parents, \r\n\r\nBased on the extracted information, \r\n";
                                        codeArray.forEach((code) => {
                                          const reply = replies.filter(
                                            (item) => item.code === code
                                          );
                                          contents +=
                                            reply[0].code +
                                            ": " +
                                            reply[0].desc +
                                            "\r\n\r\n";
                                        });
                                        const OpenEndedQuestions =
                                          getOpenEndedQuestions(rowData);

                                        contents += OpenEndedQuestions;
                                        setContents(contents);
                                      }}
                                    >
                                      <EmailIcon />
                                    </MDButton>
                                  </MDBox>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "cradle2Id" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="cradle2Id">
                              {(rowData) => {
                                return (
                                  <MDBox
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <MDTypography variant="body2">
                                      {rowData[key]}
                                    </MDTypography>
                                    <MDButton
                                      size="medium"
                                      variant="text"
                                      color="dark"
                                      iconOnly
                                      onClick={() => {
                                        newCradle2Id.current = null;
                                        docId.current = rowData.docId;
                                        setCurrentCradle2Id(rowData.cradle2Id);
                                        setOpenInputDlg(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </MDButton>
                                  </MDBox>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "group" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="group">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "dob" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="dob">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "gender" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="gender">
                              {(rowData) => {
                                return (
                                  <MDBox
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <MDTypography variant="body2">
                                      {rowData[key]}
                                    </MDTypography>
                                    <MDButton
                                      size="medium"
                                      variant="text"
                                      color="dark"
                                      iconOnly
                                      onClick={() => {
                                        newGender.current = null;
                                        docId.current = rowData.docId;
                                        setCurrentCradle2Id(rowData.cradle2Id);
                                        setCurrentGender(rowData.gender);
                                        setOpenInputGenderDlg(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </MDButton>
                                  </MDBox>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "doa" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="doa">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "ageInDays" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="ageInDays">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "ageInMonths" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="ageInMonths">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "ageInMonthsDays" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="ageInMonthsDays">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "duedateASQ3" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="duedateASQ3">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "duedateASQSE" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="duedateASQSE">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "test_retest" ? (
                          <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="test_retest">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "assessmentCodes" ? (
                          <Column {...rest} key={key}>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="assessmentCodes">
                              {(rowData) => {
                                return (
                                  <MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : key === "retest_duedate" ? (
                          <Column {...rest} key={key}>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey="retest_duedate">
                              {(rowData) => {
                                return (
                                  <>
                                    {/*<MDTypography variant="body2">
                                    {rowData[key]}
                                  </MDTypography>*/}
                                    <DatePicker
                                      name={`child1.dob`}
                                      placeholder="NA"
                                      format="yyyy-MM-dd"
                                      value={rowData[key]}
                                      placement="auto"
                                      onChange={async (selectedDate) => {
                                        console.log(rowData["retest_duedate"]);
                                        console.log(
                                          JSON.stringify(rowData["cradle2Id"])
                                        );

                                        let mDocId;
                                        const newrows = data.map((row) => {
                                          if (
                                            row.cradle2Id === rowData.cradle2Id
                                          ) {
                                            row.retest_duedate = selectedDate;
                                            mDocId = rowData.docId;
                                          }
                                          return row;
                                        });
                                        setData(newrows);

                                        // Update Retest Due Date at Firestore
                                        await updateDoc(
                                          collectionPath36mTimePoint,
                                          mDocId,
                                          {
                                            retest_duedate: selectedDate,
                                            modifiedAt: serverTimestamp(),
                                            modifiedBy: user.uid,
                                          }
                                        );
                                      }}
                                    />
                                  </>
                                );
                              }}
                            </Cell>
                          </Column>
                        ) : null;
                      })}
                    </Table>
                  </MDBox>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Card>

      <Dialog open={openDlg} onClose={handleCloseDlg} fullWidth maxWidth="md">
        <DialogTitle>
          <MDButton variant="gradient" color="info" iconOnly>
            <InfoIcon />
          </MDButton>
        </DialogTitle>
        <DialogContent style={{ height: "120px" }} dividers>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1 }}
            columnSpacing={{ xs: 1, sm: 3 }}
            p={2}
          >
            {msgDlg !== null && (
              <Grid
                item
                xs={12}
                sm={12}
                display={"flex"}
                flexDirection={"row"}
                alignItems="center"
              >
                <MDTypography variant="body3" color="dark">
                  {msgDlg}
                </MDTypography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="info"
            onClick={handleCloseDlg}
          >
            OK
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInputDlg}
        onClose={handleCloseInputDlg}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <MDBox
            display={"flex"}
            flexDirection={"row"}
            justifyContent="left"
            alignItems="center"
          >
            <MDButton variant="gradient" color="info" iconOnly>
              <InfoIcon />
            </MDButton>
            <MDBox mr={2}></MDBox>
            <MDTypography variant="body2" color="dark" fontWeight="bold">
              Update ID
            </MDTypography>
          </MDBox>
        </DialogTitle>
        <DialogContent style={{ height: "120px" }} dividers>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
            p={1}
            pl={3}
          >
            <Grid item xs={3}>
              <MDTypography variant="body3">Current ID</MDTypography>
            </Grid>
            <Grid item xs={9}>
              <MDTypography variant="body3">{currentCradle2Id}</MDTypography>
            </Grid>
            <Grid item xs={3}>
              <MDTypography variant="body3">New ID</MDTypography>
            </Grid>
            <Grid item xs={9}>
              <Input
                placeholder="New ID"
                onChange={(text) => {
                  newCradle2Id.current = text;
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="info"
            onClick={handleCloseInputDlg}
          >
            OK
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInputGenderDlg}
        onClose={handleCloseInputGenderDlg}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <MDBox
            display={"flex"}
            flexDirection={"row"}
            justifyContent="left"
            alignItems="center"
          >
            <MDButton variant="gradient" color="info" iconOnly>
              <InfoIcon />
            </MDButton>
            <MDBox mr={2}></MDBox>
            <MDTypography variant="body2" color="dark" fontWeight="bold">
              Update Gender
            </MDTypography>
          </MDBox>
        </DialogTitle>
        <DialogContent style={{ height: "120px" }} dividers>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
            p={1}
            pl={3}
          >
            <Grid item xs={3}>
              <MDTypography variant="body3">Current</MDTypography>
            </Grid>
            <Grid item xs={9}>
              <MDTypography variant="body3">{currentGender}</MDTypography>
            </Grid>
            <Grid item xs={3}>
              <MDTypography variant="body3">New</MDTypography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                name={"newGender"}
                //freeSolo
                size="small"
                //value={genderValue}
                options={["Male", "Female"]}
                disabled={false}
                renderInput={(params) => (
                  <TextField {...params} label="Select Gender" />
                )}
                getOptionLabel={(option) => option ?? ""}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, value) => {
                  if (value.length > 0) {
                    newGender.current = value;
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="warning"
            onClick={() => {
              setOpenInputGenderDlg(false);
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            size="small"
            variant="gradient"
            color="info"
            onClick={handleCloseInputGenderDlg}
          >
            OK
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMsgDlg}
        onClose={handleCloseMsgDlg}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <MDBox
            display={"flex"}
            flexDirection={"row"}
            justifyContent="left"
            alignItems="center"
          >
            <MDButton variant="gradient" color="warning" iconOnly>
              <InfoIcon />
            </MDButton>
            <MDBox mr={2}></MDBox>
            <MDTypography variant="body2" color="dark" fontWeight="bold">
              Warning
            </MDTypography>
          </MDBox>
        </DialogTitle>
        <DialogContent style={{ height: "120px" }} dividers>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
            p={1}
            pl={3}
          >
            <Grid item xs={12}>
              <MDTypography variant="body3">
                ID: {currentCradle2Id} is not found in the database!
              </MDTypography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="info"
            onClick={handleCloseMsgDlg}
          >
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Calculator36months;
