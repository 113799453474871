import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useDocument } from "hooks/useDocument";
//import { forEach } from "lodash";

const initialState = {
  data: null,
  isPending: false,
  error: null,
  success: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: null,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_FORMNUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Updated form nudges successfully.`,
        error: null,
      };
    case "CREATED_NEW_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Created a new form nudge.`,
        error: null,
      };
    case "DELETED_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Deleted a form nudge from the record.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};
const collectionPath36mTimePoint = "month36_timepoint_retestSDQ_v01";
const collectionParents = "parents";
const query = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
  orderByQueries: [
    {
      field: "cradle2Id",
      direction: "desc",
    },
  ],
};

export const useCalculatorManagerRetestSDQ = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { retrieveDocs, retrieveDoc } = useDocument();
  const { pathname } = useLocation();
  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  /* const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  ); */

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const computeAgeInDays = (dobJSDateObj, doaJSDateObj) => {
    // Compute days between two dates, excluding the time stamps
    const date1 = new Date(
      dobJSDateObj.getFullYear(),
      dobJSDateObj.getMonth(),
      dobJSDateObj.getDate()
    );

    const date2 = new Date(
      doaJSDateObj.getFullYear(),
      doaJSDateObj.getMonth(),
      doaJSDateObj.getDate()
    );

    const Difference_In_Time = date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    const Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );

    return Difference_In_Days;
  };

  const computeAgeInMonthsDays = (dobJSDateObj, doaJSDateObj) => {
    // Consider two dates, excluding the time stamps
    const date1 = new Date(
      dobJSDateObj.getFullYear(),
      dobJSDateObj.getMonth(),
      dobJSDateObj.getDate()
    );

    const date2 = new Date(
      doaJSDateObj.getFullYear(),
      doaJSDateObj.getMonth(),
      doaJSDateObj.getDate()
    );

    // Compute month
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();

    // Add months to date1
    date1.setMonth(date1.getMonth() + months);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

    if (days < 0) {
      months -= 1;
      date1.setMonth(date1.getMonth() - 1);
      Difference_In_Time = date2.getTime() - date1.getTime();
      days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    }

    const monthsStr =
      months.toString().length > 1
        ? months.toString()
        : "0" + months.toString();
    const daysStr =
      days.toString().length > 1 ? days.toString() : +"0" + days.toString();

    return monthsStr + "M " + daysStr + "D";
  };

  const computeASQ3_DueDate = (dobJSDateObj) => {
    const date1 = new Date(
      dobJSDateObj.getFullYear(),
      dobJSDateObj.getMonth(),
      dobJSDateObj.getDate()
    );

    // Add 38 months
    date1.setMonth(date1.getMonth() + 38);

    // Add 30 days
    date1.setDate(date1.getDate() + 30);
    return date1;
  };

  const computeASQSE_DueDate = (dobJSDateObj) => {
    const date1 = new Date(
      dobJSDateObj.getFullYear(),
      dobJSDateObj.getMonth(),
      dobJSDateObj.getDate()
    );

    // Add 41 months
    date1.setMonth(date1.getMonth() + 41);

    // Add 30 days
    date1.setDate(date1.getDate() + 30);
    return date1;
  };

  const getCutoffLabel = (score, CutoffLB, LabelLB, CutoffUB, LabelUB) => {
    return parseFloat(score) <= CutoffLB
      ? LabelLB
      : parseFloat(score) >= CutoffUB
      ? LabelUB
      : "GREY";
  };

  const getCutoffLabelSD = (score, CutoffB1, CutoffB2, CutoffB3, ResB1) => {
    if (ResB1 === "Very High") {
      return parseFloat(score) >= CutoffB1
        ? "Very High"
        : parseFloat(score) >= CutoffB2
        ? "High"
        : parseFloat(score) >= CutoffB3
        ? "Slightly Raised"
        : "Close to Average";
    } else {
      return parseFloat(score) <= CutoffB1
        ? "Very Low"
        : parseFloat(score) <= CutoffB2
        ? "Low"
        : parseFloat(score) <= CutoffB3
        ? "Slightly Lowered"
        : "Close to Average";
    }
  };

  const getASQ3_SubscaleLabels = useCallback((row, settings36mTimePoint) => {
    // Get "asq3" subscale labels
    // 1. Get "asq3_communicationSumLabel"
    const CutoffLB_ASQ3Communication =
      settings36mTimePoint.data.ASQ3Communication_CutoffLB; //30;
    const CutoffUB_ASQ3Communication =
      settings36mTimePoint.data.ASQ3Communication_CutoffUB; //45;
    row.asq3_communicationSumLabel = getCutoffLabel(
      row.asq3_communicationSum,
      CutoffLB_ASQ3Communication,
      "BLACK",
      CutoffUB_ASQ3Communication,
      "WHITE"
    );

    // 2. Get "asq3_grossmotorSumLabel"
    const CutoffLB_ASQ3GrossMotor =
      settings36mTimePoint.data.ASQ3GrossMotor_CutoffLB; //35;
    const CutoffUB_ASQ3GrossMotor =
      settings36mTimePoint.data.ASQ3GrossMotor_CutoffUB; //50;
    row.asq3_grossmotorSumLabel = getCutoffLabel(
      row.asq3_grossmotorSum,
      CutoffLB_ASQ3GrossMotor,
      "BLACK",
      CutoffUB_ASQ3GrossMotor,
      "WHITE"
    );

    // 3. Get "asq3_finemotorSumLabel"
    const CutoffLB_ASQ3FineMotor =
      settings36mTimePoint.data.ASQ3FineMotor_CutoffLB; //15;
    const CutoffUB_ASQ3FineMotor =
      settings36mTimePoint.data.ASQ3FineMotor_CutoffUB; //35;
    row.asq3_finemotorSumLabel = getCutoffLabel(
      row.asq3_finemotorSum,
      CutoffLB_ASQ3FineMotor,
      "BLACK",
      CutoffUB_ASQ3FineMotor,
      "WHITE"
    );

    // 4. Get "asq3_problemsolvingSumLabel"
    const CutoffLB_ASQ3ProblemSolving =
      settings36mTimePoint.data.ASQ3ProblemSolving_CutoffLB; //30;
    const CutoffUB_ASQ3ProblemSolving =
      settings36mTimePoint.data.ASQ3ProblemSolving_CutoffUB; //45;
    row.asq3_problemsolvingSumLabel = getCutoffLabel(
      row.asq3_problemsolvingSum,
      CutoffLB_ASQ3ProblemSolving,
      "BLACK",
      CutoffUB_ASQ3ProblemSolving,
      "WHITE"
    );

    // 5. Get "asq3_personalsocialSumLabel"
    const CutoffLB_ASQ3PersonalSocial =
      settings36mTimePoint.data.ASQ3PersonalSocial_CutoffLB; //35;
    const CutoffUB_ASQ3PersonalSocial =
      settings36mTimePoint.data.ASQ3PersonalSocial_CutoffUB; //45;
    row.asq3_personalsocialSumLabel = getCutoffLabel(
      row.asq3_personalsocialSum,
      CutoffLB_ASQ3PersonalSocial,
      "BLACK",
      CutoffUB_ASQ3PersonalSocial,
      "WHITE"
    );

    return row;
  }, []);

  const checkASQ3_AllWhite = (row) => {
    if (
      row.asq3_communicationSumLabel === "WHITE" &&
      row.asq3_grossmotorSumLabel === "WHITE" &&
      row.asq3_finemotorSumLabel === "WHITE" &&
      row.asq3_problemsolvingSumLabel === "WHITE" &&
      row.asq3_personalsocialSumLabel === "WHITE"
    )
      return true;
    else return false;
  };

  const checkASQ3_AnyGrey = (row) => {
    if (
      row.asq3_communicationSumLabel === "GREY" ||
      row.asq3_grossmotorSumLabel === "GREY" ||
      row.asq3_finemotorSumLabel === "GREY" ||
      row.asq3_problemsolvingSumLabel === "GREY" ||
      row.asq3_personalsocialSumLabel === "GREY"
    )
      return true;
    else return false;
  };

  const checkASQ3_AnyBlack = (row) => {
    if (
      row.asq3_communicationSumLabel === "BLACK" ||
      row.asq3_grossmotorSumLabel === "BLACK" ||
      row.asq3_finemotorSumLabel === "BLACK" ||
      row.asq3_problemsolvingSumLabel === "BLACK" ||
      row.asq3_personalsocialSumLabel === "BLACK"
    )
      return true;
    else return false;
  };

  const checkASQ3_NoBlack = (row) => {
    if (
      row.asq3_communicationSumLabel === "BLACK" ||
      row.asq3_grossmotorSumLabel === "BLACK" ||
      row.asq3_finemotorSumLabel === "BLACK" ||
      row.asq3_problemsolvingSumLabel === "BLACK" ||
      row.asq3_personalsocialSumLabel === "BLACK"
    )
      return false;
    else return true;
  };

  const getSDQ_SubscaleLabels = useCallback((row, settings36mTimePoint) => {
    // Get "sdq" subscale labels
    // 1. Get "sd_emotionalproblemsLabel"
    let CutoffB1 = settings36mTimePoint.data.SDQ_EmotionalProb_CutoffB1; //5;
    let CutoffB2 = settings36mTimePoint.data.SDQ_EmotionalProb_CutoffB2; //4;
    let CutoffB3 = settings36mTimePoint.data.SDQ_EmotionalProb_CutoffB3; //3;
    let ResB1 = "Very High";
    row.sd_emotionalproblemsLabel = getCutoffLabelSD(
      row.sd_emotionalproblems,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    // 2. Get "sd_conductproblemsLabel"
    CutoffB1 = settings36mTimePoint.data.SDQ_ConductProb_CutoffB1; //6;
    CutoffB2 = settings36mTimePoint.data.SDQ_ConductProb_CutoffB2; //5;
    CutoffB3 = settings36mTimePoint.data.SDQ_ConductProb_CutoffB3; //4;
    ResB1 = "Very High";
    row.sd_conductproblemsLabel = getCutoffLabelSD(
      row.sd_conductproblems,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    // 3. Get "sd_hyperactivityLabel"
    CutoffB1 = settings36mTimePoint.data.SDQ_Hyperactivity_CutoffB1; //8;
    CutoffB2 = settings36mTimePoint.data.SDQ_Hyperactivity_CutoffB2; //7;
    CutoffB3 = settings36mTimePoint.data.SDQ_Hyperactivity_CutoffB3; //6;
    ResB1 = "Very High";
    row.sd_hyperactivityLabel = getCutoffLabelSD(
      row.sd_hyperactivity,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    // 4. Get "sd_peerproblemsLabel"
    CutoffB1 = settings36mTimePoint.data.SDQ_PeerProb_CutoffB1; //5;
    CutoffB2 = settings36mTimePoint.data.SDQ_PeerProb_CutoffB2; //4;
    CutoffB3 = settings36mTimePoint.data.SDQ_PeerProb_CutoffB3; //3;
    ResB1 = "Very High";
    row.sd_peerproblemsLabel = getCutoffLabelSD(
      row.sd_peerproblems,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    // 5. Get "sd_prosocialLabel"
    CutoffB1 = settings36mTimePoint.data.SDQ_Prosocial_CutoffB1; //4;
    CutoffB2 = settings36mTimePoint.data.SDQ_Prosocial_CutoffB2; //5;
    CutoffB3 = settings36mTimePoint.data.SDQ_Prosocial_CutoffB3; //6;
    ResB1 = "Very Low";
    row.sd_prosocialLabel = getCutoffLabelSD(
      row.sd_prosocial,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    // 6. Get "sd_totalLabel"
    CutoffB1 = settings36mTimePoint.data.SDQ_Total_CutoffB1; //19;
    CutoffB2 = settings36mTimePoint.data.SDQ_Total_CutoffB2; //16;
    CutoffB3 = settings36mTimePoint.data.SDQ_Total_CutoffB3; //13;
    ResB1 = "Very High";
    row.sd_totalLabel = getCutoffLabelSD(
      row.sd_total,
      CutoffB1,
      CutoffB2,
      CutoffB3,
      ResB1
    );

    return row;
  }, []);

  const checkSDQ_AllAverage = (row) => {
    if (
      row.sd_emotionalproblemsLabel === "Close to Average" &&
      row.sd_conductproblemsLabel === "Close to Average" &&
      row.sd_hyperactivityLabel === "Close to Average" &&
      row.sd_peerproblemsLabel === "Close to Average" &&
      row.sd_prosocialLabel === "Close to Average" &&
      row.sd_totalLabel === "Close to Average"
    )
      return true;
    else return false;
  };

  const checkSDQ_AnySlightlyRaised = (row) => {
    if (
      row.sd_emotionalproblemsLabel === "Slightly Raised" ||
      row.sd_conductproblemsLabel === "Slightly Raised" ||
      row.sd_hyperactivityLabel === "Slightly Raised" ||
      row.sd_peerproblemsLabel === "Slightly Raised" ||
      row.sd_prosocialLabel === "Slightly Lowered" ||
      row.sd_totalLabel === "Slightly Raised"
    )
      return true;
    else return false;
  };

  const checkSDQ_AnyHighVeryHigh = (row) => {
    if (
      row.sd_emotionalproblemsLabel === "Very High" ||
      row.sd_emotionalproblemsLabel === "High" ||
      row.sd_conductproblemsLabel === "Very High" ||
      row.sd_conductproblemsLabel === "High" ||
      row.sd_hyperactivityLabel === "Very High" ||
      row.sd_hyperactivityLabel === "High" ||
      row.sd_peerproblemsLabel === "Very High" ||
      row.sd_peerproblemsLabel === "High" ||
      row.sd_prosocialLabel === "Very Low" ||
      row.sd_prosocialLabel === "Low" ||
      row.sd_totalLabel === "Very High" ||
      row.sd_totalLabel === "High"
    )
      return true;
    else return false;
  };

  const checkSDQ_NoHighVeryHigh = (row) => {
    if (
      row.sd_emotionalproblemsLabel === "Very High" ||
      row.sd_emotionalproblemsLabel === "High" ||
      row.sd_conductproblemsLabel === "Very High" ||
      row.sd_conductproblemsLabel === "High" ||
      row.sd_hyperactivityLabel === "Very High" ||
      row.sd_hyperactivityLabel === "High" ||
      row.sd_peerproblemsLabel === "Very High" ||
      row.sd_peerproblemsLabel === "High" ||
      row.sd_prosocialLabel === "Very Low" ||
      row.sd_prosocialLabel === "Low" ||
      row.sd_totalLabel === "Very High" ||
      row.sd_totalLabel === "High"
    )
      return false;
    else return true;
  };

  const getAssessment = useCallback((row) => {
    let assessmentCodes = [];
    if (
      checkASQ3_AllWhite(row) &&
      row.asqse2_totalLabel === "WHITE" &&
      checkSDQ_AllAverage(row)
    ) {
      assessmentCodes.push("NR_NC");
      assessmentCodes.push("NR_C");
    }

    const cond4 =
      checkASQ3_NoBlack(row) &&
      row.asqse2_totalLabel !== "BLACK" &&
      checkSDQ_NoHighVeryHigh(row);
    if (
      (checkASQ3_AnyGrey(row) ||
        row.asqse2_totalLabel === "GREY" ||
        checkSDQ_AnySlightlyRaised(row)) &&
      checkASQ3_NoBlack(row) &&
      cond4
    ) {
      if (row.test_retest === "Test") {
        assessmentCodes.push("LR_NC");
        assessmentCodes.push("LR_C");
      } else {
        assessmentCodes.push("LR_NC_rd");
        assessmentCodes.push("LR_C_rd");
      }
    }

    if (
      checkASQ3_AnyBlack(row) ||
      row.asqse2_totalLabel === "BLACK" ||
      checkSDQ_AnyHighVeryHigh(row)
    ) {
      if (row.test_retest === "Test") {
        assessmentCodes.push("HR_NC");
        assessmentCodes.push("HR_C");
      } else {
        assessmentCodes.push("HR_NC_rd");
        assessmentCodes.push("HR_C_rd");
      }
    }

    return assessmentCodes;
  }, []);

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/forms/calculator36months-retestSDQ")) {
        throw operationInvalidError;
      }

      const retrieved36mTimePoint = await retrieveDocs(
        collectionPath36mTimePoint,
        query
      );

      const settings36mTimePoint = await retrieveDoc(
        "settings",
        "month36_timepoint"
      );

      const rows = await Promise.all(
        retrieved36mTimePoint.map(async (row) => {
          let newRow = row.data;
          newRow.docId = row.id;
          // 1. Get "Date of Assessment"
          const doaJSDateObj =
            row.data.timestamp.length === 0
              ? row.data.createdAt.toDate()
              : row.data.timestamp.toDate();
          newRow.doa = doaJSDateObj.toLocaleDateString("en-SG");

          // 1. Remove empty space at the start and end of the ID
          newRow.cradle2Id = newRow.cradle2Id.trim();

          // 1. Get "asq3" sub-scale labels
          newRow = getASQ3_SubscaleLabels(newRow, settings36mTimePoint);

          // 2. Get asqse2_totalLabel
          const CutoffLB_ASQ3_SE2 = settings36mTimePoint.data.ASQ3_SE2_CutoffLB; //70;
          const CutoffUB_ASQ3_SE2 = settings36mTimePoint.data.ASQ3_SE2_CutoffUB; //105;
          newRow.asqse2_totalLabel = getCutoffLabel(
            newRow.asqse2_total,
            CutoffLB_ASQ3_SE2,
            "WHITE",
            CutoffUB_ASQ3_SE2,
            "BLACK"
          );

          // 3. Get "sdq" sub-scale labels
          newRow = getSDQ_SubscaleLabels(newRow, settings36mTimePoint);

          // 2. Get "Retest_duedate". Convert Firebase timestamp format to JS Date format
          newRow.retest_duedate =
            row.data.retest_duedate === null ||
            row.data.retest_duedate.length === 0
              ? null
              : row.data.retest_duedate.toDate();

          // Retrieve record in Parents collection based on "cradle2Id"
          // To get: "group", "dob", "ageInDays", "ageInMonths"
          const cradle2Id = row.data.cradle2Id.toUpperCase();
          const qry = {
            whereQueries: [
              {
                field: "userName",
                condition: "==",
                value: cradle2Id,
              },
            ],
            orderByQueries: [
              {
                field: "userName",
                direction: "desc",
              },
            ],
          };
          let retrievedParents = await retrieveDocs(collectionParents, qry);
          // If no record, retry by convert cradle2Id to lower case
          if (retrievedParents.length === 0) {
            const cradle2Id = row.data.cradle2Id.toLowerCase();
            const qry = {
              whereQueries: [
                {
                  field: "userName",
                  condition: "==",
                  value: cradle2Id,
                },
              ],
              orderByQueries: [
                {
                  field: "userName",
                  direction: "desc",
                },
              ],
            };
            retrievedParents = await retrieveDocs("parents", qry);
          }

          if (retrievedParents.length > 0) {
            newRow.group =
              retrievedParents[0].data.children[0].participantGroup;

            const dobJSDateObj =
              retrievedParents[0].data.children[0].dob.toDate();
            newRow.dob = dobJSDateObj.toLocaleDateString("en-SG");
            newRow.ageInDays = computeAgeInDays(dobJSDateObj, doaJSDateObj);
            newRow.ageInMonths = parseFloat(newRow.ageInDays / 30).toFixed(1);
            newRow.ageInMonthsDays = computeAgeInMonthsDays(
              dobJSDateObj,
              doaJSDateObj
            );
            const duedateASQ3_JSDateObj = computeASQ3_DueDate(dobJSDateObj);
            newRow.duedateASQ3 =
              duedateASQ3_JSDateObj.toLocaleDateString("en-SG");
            const duedateASQSE_JSDateObj = computeASQSE_DueDate(dobJSDateObj);
            newRow.duedateASQSE =
              duedateASQSE_JSDateObj.toLocaleDateString("en-SG");
            newRow.gender =
              retrievedParents[0].data.children[0].gender !== undefined
                ? retrievedParents[0].data.children[0].gender
                : "";
          } else {
            newRow.group = "NIL";
            newRow.dob = "NIL";
            newRow.ageInDays = "NIL";
            newRow.ageInMonths = "NIL";
            newRow.duedateASQ3 = "NIL";
            newRow.duedateASQSE = "NIL";
            newRow.gender = "NIL";
          }

          return newRow;
        })
      );

      let uniqueCradle2Ids = [];
      rows.forEach((row) => {
        if (uniqueCradle2Ids.includes(row.cradle2Id) === false)
          uniqueCradle2Ids.push(row.cradle2Id);
      });

      let newRows = [];
      uniqueCradle2Ids.forEach((elm) => {
        const subRows = rows.filter((row) => row.cradle2Id === elm);
        if (subRows.length === 1) {
          const newRow = subRows[0];
          newRow.test_retest = "Test";
          newRows.push(newRow);
        } else {
          const ageInMonths = subRows.map((row) => {
            return parseFloat(row.ageInMonths);
          });
          const minAge = Math.min(...ageInMonths);
          subRows.forEach((row) => {
            row.test_retest =
              parseFloat(row.ageInMonths) > minAge ? "Retest" : "Test";
            newRows.push(row);
          });
        }
      });

      newRows.forEach((newRow) => {
        const assessmentCodes = getAssessment(newRow);
        newRow.assessmentCodes = assessmentCodes.toString();
      });

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: newRows, //retrieved36mTimePoint,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    pathname,
    dispatchError,
    retrieveDocs,
    retrieveDoc,
    getASQ3_SubscaleLabels,
    getSDQ_SubscaleLabels,
    getAssessment,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "36 months (Retest SDQ)";
  return {
    modeTitle,
    response,
  };
};
