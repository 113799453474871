import { functions } from "configs/firebase";
import { httpsCallable } from "firebase/functions";

const sendSignupEmail = httpsCallable(functions, "sendSignupEmail");
const sendWhatsAppMsg = httpsCallable(functions, "sendWhatsAppMsg");
const sendWhatsAppFormTemplate = httpsCallable(
  functions,
  "sendWhatsAppFormTemplate"
);
const revokeRefreshToken = httpsCallable(functions, "revokeRefreshToken");

export {
  sendSignupEmail,
  sendWhatsAppMsg,
  revokeRefreshToken,
  sendWhatsAppFormTemplate,
};
